<template>
  <v-card>
    <v-card-title>{{ isEditingCustomer ? 'Edit Customer' : 'Add New Customer' }}</v-card-title>
    <v-form
      ref="customerEntryForm"
      v-model="validForm"
    >
      <v-card-text>
        <v-text-field v-model="customerName" label="Customer Name"></v-text-field>
        <v-text-field
          v-model="bookingNumber"
          label="Booking Number"
          :rules="bookingNumberRules"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="cancelButtonClicked">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!validForm"
          @click="saveButtonClicked"
        >{{ isEditingCustomer ? 'Save Changes' : 'Add Customer' }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data() {
    return {
      customerName: '',
      bookingNumber: '',
      bookingNumberRules: [rules.rules.alphaNumeric],
      validForm: true,
    };
  },
  computed: {
    ...mapGetters('customer', ['selectedCustomer', 'isEditingCustomer']),
  },
  methods: {
    ...mapActions('customer', [
      'addCustomer',
      'updateCustomer',
      'setShowCustomerEntry',
      'setSelectedCustomerName',
      'setIsEditingCustomer',
    ]),
    saveButtonClicked() {
      const isValid = this.$refs.customerEntryForm.validate();
      if (isValid) {
        if (this.isEditingCustomer) {
          this.updateCustomer({
            ...this.selectedCustomer,
            name: this.customerName,
            bookingNumber: this.bookingNumber,
          });
        } else {
          this.addCustomer({
            name: this.customerName,
            bookingNumber: this.bookingNumber,
          });
        }
        this.setIsEditingCustomer(false);
        this.setShowCustomerEntry(false);
        this.setSelectedCustomerName(this.customerName);
        this.customerName = '';
        this.bookingNumber = '';
      }
    },
    cancelButtonClicked() {
      this.setIsEditingCustomer(false);
      this.setShowCustomerEntry(false);
      this.customerName = '';
      this.bookingNumber = '';
    },
  },
  watch: {
    isEditingCustomer: {
      handler(newVal) {
        if (newVal && this.selectedCustomer) {
          this.customerName = this.selectedCustomer.name;
          this.bookingNumber = this.selectedCustomer.bookingNumber || '';
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.v-card {
  padding: 1em;
}
</style>
