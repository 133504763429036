import { render, staticRenderFns } from "./ManageCustomersView.vue?vue&type=template&id=c6c32c72&scoped=true"
import script from "./ManageCustomersView.vue?vue&type=script&lang=js"
export * from "./ManageCustomersView.vue?vue&type=script&lang=js"
import style0 from "./ManageCustomersView.vue?vue&type=style&index=0&id=c6c32c72&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6c32c72",
  null
  
)

export default component.exports