<template>
  <div class="button-group">
    <v-btn @click="clearSelection">Change Customer</v-btn>
    <v-btn
      color="primary"
      :disabled="!hasCustomerChanges || hasInvalidForm"
      @click="saveCustomerChanges"
    >Save Changes</v-btn>
    <v-btn color="error" @click="deleteButtonClicked">Delete Customer</v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    editableCustomer: {
      type: Object,
      required: true,
    },
    hasInvalidForm: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('customer', [
      'selectedCustomer',
    ]),
    hasCustomerChanges() {
      if (!this.selectedCustomer) return false;
      return (
        this.editableCustomer.name !== this.selectedCustomer.name
        || this.editableCustomer.bookingNumber !== (this.selectedCustomer.bookingNumber || '')
      );
    },
  },
  methods: {
    ...mapActions('customer', [
      'updateCustomer',
      'setSelectedCustomerName',
      'setShowDeleteConfirmation',
    ]),
    clearSelection() {
      this.setSelectedCustomerName('');
    },
    deleteButtonClicked() {
      this.setShowDeleteConfirmation(true);
    },
    async saveCustomerChanges() {
      if (this.hasCustomerChanges && !this.hasInvalidForm) {
        await this.updateCustomer({
          ...this.selectedCustomer,
          ...this.editableCustomer,
        });
      }
    },
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  gap: 1em;
  margin-bottom: 0.75em;
}
</style>
