<template>
  <v-select
    v-model="customer"
    :items="customers"
    item-text="name"
    item-value="name"
    label="Select Customer"
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('customer', [
      'setSelectedCustomerName',
      'setCustomers',
    ]),
  },
  computed: {
    ...mapGetters('customer', [
      'customers',
      'selectedCustomer',
    ]),
    customer: {
      get() {
        return this.selectedCustomer?.name;
      },
      set(customerName) {
        this.setSelectedCustomerName(customerName);
      },
    },
  },
  async created() {
    await this.setCustomers();
  },
};
</script>

<style scoped>

</style>
