<template>
  <div class="locations-section">
    <!-- Spot Range Selection Dialog -->
    <v-dialog v-model="isSpotRangeDialogVisible" max-width="800">
      <template v-slot:default>
        <SpotRangeSelection />
      </template>
    </v-dialog>

    <!-- Display Selected Ranges -->
    <v-container class="range-container">
      <v-row v-for="(range, index) in selectedRanges" :key="index" align="center" class="range-row">
        <v-col cols="auto">
          <span>Start: <strong class="highlight">{{ range.start.displayName }}</strong> - End: <strong class="highlight">{{ range.end.displayName }}</strong></span>
        </v-col>
        <v-col cols="auto">
          <v-btn color="error" @click="removeRange(index)">Remove</v-btn>
        </v-col>
      </v-row>
      <v-btn class="add-range-button" @click="isSpotRangeDialogVisible = true">Add Range</v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpotRangeSelection from '@/components/spotRangeSelection.vue';

export default {
  components: {
    SpotRangeSelection,
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('customer', [
      'removeLocationRange',
      'setShowSpotRangeSelection',
    ]),
    removeRange(index) {
      this.removeLocationRange(index);
    },
  },
  computed: {
    ...mapGetters('customer', [
      'showSpotRangeSelection',
      'selectedRanges',
    ]),
    isSpotRangeDialogVisible: {
      get() {
        return this.showSpotRangeSelection;
      },
      set(value) {
        this.setShowSpotRangeSelection(value);
      },
    },
  },
};
</script>

<style scoped>
.locations-section {
  margin-top: 1em;
}

.range-container {
  padding-left: 0;
  margin-left: 0;
  max-width: 100%;
}

.range-row {
  justify-content: flex-start;
  max-width: 100%;
  align-items: center;
}

.add-range-button {
  margin-top: 1em;
}

.highlight {
  color: black;
  font-weight: bold;
  font-size: 1.2em;
}
</style>
