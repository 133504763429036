<template>
  <div>
    <h1 class="page-title">Customer Management</h1>
    <div v-if="!selectedCustomer" class="customer-selection">
      <CustomerSelect />
      <v-btn color="primary" class="ml-4" @click="addButtonClicked">Add New Customer</v-btn>
    </div>

    <div v-else class="customer-edit">
      <div class="customer-fields">
        <div>
          <CustomerManagementButtons
            :editable-customer="editableCustomer"
            :has-invalid-form="!validForm"
          />
          <v-form
            ref="customerForm"
            v-model="validForm"
          >
            <div class="form-fields">
              <v-text-field
                v-model="editableCustomer.name"
                label="Customer Name"
              ></v-text-field>
              <v-text-field
                v-model="editableCustomer.bookingNumber"
                label="Booking Number"
                :rules="bookingNumberRules"
              ></v-text-field>
              <div class="field-group">
                <div class="field-label">Location ID Ranges</div>
                <CustomerLocations />
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </div>

    <v-dialog v-model="showCustomerEntry" max-width="600">
      <CustomerEntry />
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmation" max-width="400">
      <CustomerDeleteConfirmation />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CustomerLocations from '@/components/customerLocations.vue';
import CustomerSelect from '@/components/customerSelect.vue';
import CustomerEntry from '@/components/customerEntry.vue';
import CustomerDeleteConfirmation from '@/components/customerDeleteConfirmation.vue';
import CustomerManagementButtons from '@/components/customerManagementButtons.vue';
import rules from '../constants/rules';

export default {
  components: {
    CustomerLocations,
    CustomerDeleteConfirmation,
    CustomerEntry,
    CustomerSelect,
    CustomerManagementButtons,
  },
  data() {
    return {
      editableCustomer: {
        name: '',
        bookingNumber: '',
      },
      bookingNumberRules: [rules.rules.alphaNumeric],
      validForm: true,
    };
  },
  computed: {
    ...mapGetters('customer', [
      'selectedCustomer',
      'showCustomerEntry',
      'showDeleteConfirmation',
    ]),
    hasInvalidBookingNumber() {
      return this.editableCustomer.bookingNumber
        && !rules.rules.alphaNumeric(this.editableCustomer.bookingNumber);
    },
  },
  methods: {
    ...mapActions('customer', [
      'setShowCustomerEntry',
    ]),
    addButtonClicked() {
      this.setShowCustomerEntry(true);
    },
  },
  watch: {
    selectedCustomer: {
      immediate: true,
      handler(customer) {
        if (customer) {
          this.editableCustomer = {
            name: customer.name,
            bookingNumber: customer.bookingNumber || '',
          };
        }
      },
    },
  },
};
</script>

<style scoped>
.page-title {
  margin-bottom: 0.5em;
}

.customer-selection {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.customer-edit {
  margin-bottom: 2em;
}

.customer-fields {
  max-width: 800px;
}

.form-fields {
  margin-top: 0.75em;
}

.field-group {
  margin-top: 1em;
}

.field-label {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
}
</style>
